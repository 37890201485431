import React, { createContext, useReducer, useContext } from 'react';

import { getConfig } from 'src/api';
import { FormValues as StepOneFormValues } from 'src/components/FormSteps/StepOne';
import { FormValues as StepTwoFormValues } from 'src/components/FormSteps/StepTwo';
import { FormValues as StepThreeFormValues } from 'src/components/FormSteps/StepThree';

interface IState {
  config: Awaited<ReturnType<typeof getConfig>> | null;
  form: {
    stepOne: StepOneFormValues;
    stepTwo: StepTwoFormValues;
    stepThree: StepThreeFormValues;
  };
}

type Action =
  | { type: 'SET_CONFIG'; payload: IState['config'] }
  | {
      type: 'UPDATE_FORM_DATA';
      payload: {
        target: keyof IState['form'];
        data: IState['form'][keyof IState['form']];
      };
    };

const StateContext = createContext<IState | null>(null);
const DispatchContext = createContext<React.Dispatch<Action> | null>(null);

const initialState: IState = {
  config: null,
  form: {
    stepOne: {
      name: '',
      battle: null,
      division: null,
      unit: null,
      soldier: [],
    },
    stepTwo: {
      story: '',
      photos: [{ state: 'default', url: '' }],
      confirmation: false,
    },
    stepThree: {
      name: '',
      email: '',
      phone: '',
      confirmation: false,
    },
  },
};

function reducer(state: IState, action: Action): IState {
  switch (action.type) {
    case 'SET_CONFIG':
      return { ...state, config: action.payload };

    case 'UPDATE_FORM_DATA':
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.target]: action.payload.data,
        },
      };

    default:
      return state;
  }
}

export function AppContextProvider(props: React.PropsWithChildren) {
  const [state, dispatch] = useReducer(reducer, initialState);

  React.useEffect(() => {
    const fetchData = async () => {
      const config = await getConfig();

      if (config) {
        dispatch({ type: 'SET_CONFIG', payload: config });
      }
    };

    fetchData();
  }, []);

  if (!state.config) {
    return null;
  }

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{props.children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);

  if (!context) {
    throw new Error('useAppState must be used within a AppContextProvider');
  }

  return context as { [K in keyof IState]: NonNullable<IState[K]> };
}

export function useAppDispatch() {
  const dispatch = useContext(DispatchContext);

  if (!dispatch) {
    throw new Error('useAppDispatch must be used within a AppContextProvider');
  }

  return dispatch;
}
