import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MuiTextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import { Formik, Form } from 'formik';

import { TextField, Autocomplete, Button } from 'src/components/form';
import { useAppDispatch, useAppState } from 'src/Context';
import builtin_logo from './assets/builtin-logo.svg';

const Container = styled(Box, { label: 'Container' })(() => ({
  position: 'relative',
}));

const Logo = styled('img', { label: 'Logo' })(() => ({
  display: 'block',
  height: '6.7rem',
  marginBottom: '0.7rem',
}));

const ButtonsContainer = styled(Box, { label: 'ButtonsContainer' })(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  flexDirection: 'row-reverse',
  marginTop: '1.5rem',

  [theme.breakpoints.down('md')]: {
    '& > div': {
      width: '100%',

      '& > button': {
        width: '100%',
      },
    },
  },
}));

export interface FormValues {
  name: string;
  battle: { value: string; title: string; category: string } | null;
  division: { value: string; title: string } | null;
  unit: { value: string; title: string; category: string } | null;
  soldier: { value: string; name: string; rank: string }[];
}

interface Props {
  onNext: () => void;
}

const StepOne = (props: Props) => {
  const { config, form } = useAppState();
  const dispatch = useAppDispatch();
  const { clientForm: texts } = config.texts;

  const options = React.useMemo(() => {
    return {
      battle: Object.values(config.battles).map((battle) => ({
        value: battle._id,
        title: battle.title,
        category: battle.categoryTitle,
      })),
      division: Object.values(config.divisions)
        .map((division) => ({
          value: division._id,
          title: division.title,
        }))
        .sort((a, b) => b.title.localeCompare(a.title)),
      unit: Object.values(config.units).map((unit) => ({
        value: unit._id,
        title: unit.title,
        category: unit.categoryTitle === 'Unknown Category' ? '' : unit.categoryTitle,
      })),
      soldier: Object.values(config.soldiers)
        .map((soldier) => ({
          value: soldier._id,
          name: soldier.name,
          rank: soldier.rankTitle === 'Unknown Rank' ? '' : soldier.rankTitle,
        }))
        .sort((a, b) => b.rank.localeCompare(a.rank)),
    };
  }, [config]);

  const onSubmit = (values: FormValues) => {
    dispatch({ type: 'UPDATE_FORM_DATA', payload: { target: 'stepOne', data: values } });
    props.onNext();
  };

  useEffect(() => {
    ReactGA.ga('send', 'pageview', '/First page');
  }, []);

  return (
    <Container>
      <Logo src={builtin_logo} />
      <Typography variant="stepSubTitle" mb="0.8rem" mt="10px" component="p">
        {texts.stepOne.subTitle}
      </Typography>
      <Box>
        <Formik
          initialValues={form.stepOne}
          validateOnBlur
          validate={(values) => {
            const splittedName = values.name.split(' ');

            if (values.name.trim() === '') {
              return { name: texts.validation.stepOne.soldierName.required };
            } else if (splittedName.length < 2 || splittedName.some((name) => name.length < 2)) {
              const msg = texts.validation.stepOne.soldierName.invalidValue;
              return { name: msg };
            }
          }}
          onSubmit={onSubmit}
        >
          <Form>
            <TextField
              name="name"
              label={'* ' + texts.stepOne.fields.soldierName.label}
              placeholder={texts.stepOne.fields.soldierName.placeholder}
            />
            <Autocomplete
              name="battle"
              label={texts.stepOne.fields.battle.label}
              defaultValue={form.stepOne.battle}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              options={options.battle}
              getOptionLabel={(option) => (option as (typeof options.battle)[number]).title}
              groupBy={(option) => option.category}
              renderOption={(props, option) => <li {...props}>{option.title}</li>}
              renderInput={(params) => (
                <MuiTextField {...params} placeholder={texts.stepOne.fields.battle.placeholder} />
              )}
            />
            <Autocomplete
              name="division"
              label={texts.stepOne.fields.division.label}
              defaultValue={form.stepOne.division}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              options={options.division}
              getOptionLabel={(option) => (option as (typeof options.division)[number]).title}
              renderOption={(props, option) => <li {...props}>{option.title}</li>}
              renderInput={(params) => (
                <MuiTextField {...params} placeholder={texts.stepOne.fields.division.placeholder} />
              )}
            />
            <Autocomplete
              name="unit"
              label={texts.stepOne.fields.unit.label}
              defaultValue={form.stepOne.unit}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              options={options.unit}
              getOptionLabel={(option) => (option as (typeof options.unit)[number]).title}
              groupBy={(option) => option.category}
              renderOption={(props, option) => <li {...props}>{option.title}</li>}
              renderInput={(params) => <MuiTextField {...params} placeholder={texts.stepOne.fields.unit.placeholder} />}
            />
            <Autocomplete
              name="soldier"
              multiple
              disableCloseOnSelect
              label={texts.stepOne.fields.place.label}
              defaultValue={form.stepOne.soldier}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              options={options.soldier}
              getOptionLabel={(option) => (option as (typeof options.soldier)[number]).name}
              renderOption={(props, option, { selected }) => (
                <li {...props} className={`${props.className} multiselect`}>
                  <Checkbox checked={selected} />
                  {option.rank} - {option.name}
                </li>
              )}
              freeSolo
              isVirtualList
              renderInput={(params) => (
                <MuiTextField {...params} placeholder={texts.stepOne.fields.place.placeholder} />
              )}
            />
            <ButtonsContainer>
              <Button type="submit" primary label={texts.nextBtn} />
            </ButtonsContainer>
          </Form>
        </Formik>
      </Box>
    </Container>
  );
};

export default StepOne;
