import React from 'react';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

const Label = styled((props) => <Typography {...props} component="label" />, { label: 'Label' })(() => ({
  fontSize: '1.6rem',
  lineHeight: '1.75rem',
  marginBottom: '0.4rem',
  color: '#F5F5F5',
}));

export default Label;
