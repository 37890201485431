import { ApiResponse } from '@api/types';
import { API_HOST } from '@api/constants/routes';
import { Result as PublishConfigResult } from '@api/routes/config/publish/params';
import {
  ROUTE as UPLOAD_PHOTO_ROUTE,
  Params as UploadPhotoParams,
  Result as UploadPhotoResult,
} from '@api/routes/client-form/upload-photo/params';
import {
  ROUTE as SUBMIT_FORM_ROUTE,
  Params as SubmitFormParams,
  Result as SubmitFormResult,
} from '@api/routes/client-form/submit/params';

export const getConfig = async (): Promise<PublishConfigResult> => {
  let CONFIG_URL = '';

  switch (process.env.ENV) {
    case 'production':
      CONFIG_URL = `https://makokipur.cdn-il.com/client-form/config-prod.json`;
      break;

    case 'development':
    case 'local':
    default:
      CONFIG_URL = `https://storage.googleapis.com/${process.env.PROJECT_ID}.appspot.com/client-form%2Fconfig-dev.json`;
      break;
  }

  const response = await fetch(CONFIG_URL, { method: 'GET' });
  const result = await response.json();

  return result;
};

export const uploadPhoto = async (
  params: UploadPhotoParams,
  onProgress: (progress: number) => void,
): Promise<ApiResponse<UploadPhotoResult>> => {
  const result = await new Promise<ApiResponse<UploadPhotoResult>>((resolve, reject) => {
    const formData = new FormData();
    const request = new XMLHttpRequest();

    formData.append('photo', renameFile(params.photo as File, 'photo'));

    request.upload.onprogress = (event) => {
      const progress = Math.round((event.loaded / event.total) * 100);
      onProgress(progress);
    };

    request.onload = () => {
      try {
        const response = JSON.parse(request.response);
        resolve(response as ApiResponse<UploadPhotoResult>);
      } catch (e) {
        reject(e);
      }
    };

    request.open('POST', `${API_HOST}${UPLOAD_PHOTO_ROUTE}`);
    request.send(formData);
  });

  return result;
};

export const submitForm = async (params: SubmitFormParams): Promise<ApiResponse<SubmitFormResult>> => {
  const response = await fetch(`${API_HOST}${SUBMIT_FORM_ROUTE}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
  const result = await response.json();

  return result;
};

const renameFile = (file: File, name: string) => {
  const blob = file.slice(0, file.size, file.type);
  return new File([blob], `${name}.${file.type.split('/')[1]}`, { type: file.type });
};
