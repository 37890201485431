import React from 'react';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

const Container = styled('div', { label: 'Error' })(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

const ErrorMsg = styled(Typography, { label: 'ErrorMsg' })(() => ({
  fontSize: '1.2rem',
  lineHeight: '1.315rem',
  marginTop: '0.4rem',
  color: '#fff',
  backgroundColor: '#EC0203',
}));

interface Props {
  error: string | string[];
}

const Error = (props: Props) => {
  const { error } = props;
  const errors = Array.isArray(error) ? error : [error];

  return (
    <Container>
      {errors.map((error, index) => (
        <ErrorMsg key={`${error}-${index}`}>{error}</ErrorMsg>
      ))}
    </Container>
  );
};

export default Error;
