import React from 'react';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';

import { useAppState } from 'src/Context';
import PhotoUploaderSlot, { SlotState } from './PhotoUploaderSlot';

const Container = styled(Box, { label: 'Container' })(() => ({
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  gap: '1.9rem',
  marginBottom: '1.6rem',
}));

const Disclaimer = styled(Box, { label: 'Disclaimer', shouldForwardProp: (prop) => prop !== 'singleLine' })<{
  singleLine: boolean;
}>(({ singleLine }) => ({
  width: '12.0rem',
  height: '10.4rem',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',

  ...(singleLine && {
    width: '100%',
    height: 'auto',
    marginTop: '-0.5rem',
  }),
}));

export interface SlotData {
  state: SlotState;
  url?: string;
}

interface Props {
  maxAmount: number;
  slots: SlotData[];
  onChange: (slots: SlotData[]) => void;
}

const MB = 1024 * 1024;
const SIZE_LIMIT = 10 * MB;

const PhotoUploader = (props: Props) => {
  const { maxAmount } = props;
  const { config } = useAppState();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const disclaimerSingleLineValue = isMobile ? 1 : 2;
  const [slots, setSlots] = React.useState<SlotData[]>([...props.slots]);
  const [totalFilesSize, setTotalFilesSize] = React.useState({});

  const onTotalSizeChange = (index: number, size: number) => {
    setTotalFilesSize((prevState) => ({ ...prevState, ...{ [index]: size } }));
  };

  const isSizeLimitOverflow = React.useMemo(() => {
    // @ts-ignore this type is obviously a number
    const totalSize: number = Object.values(totalFilesSize).reduce((total, current) => {
      // @ts-ignore this type is obviously a number
      const size = total + current;
      return size;
    }, 0);

    return totalSize > SIZE_LIMIT;
  }, [totalFilesSize]);

  const onUploadComplete = (index: number, url: string) => {
    const newSlots = [...slots];

    newSlots[index] = { state: 'success', url };
    if (newSlots.length < maxAmount) newSlots.push({ state: 'default', url: '' });
    setSlots(newSlots);
    props.onChange(newSlots);
  };

  const onRemove = (index: number) => {
    const newSlots = [...slots];

    newSlots.splice(index, 1);
    if (newSlots.length < maxAmount && newSlots.at(-1)?.state !== 'default') {
      newSlots.push({ state: 'default', url: '' });
    }
    setSlots(newSlots);
    props.onChange(newSlots);
  };

  React.useEffect(() => {
    console.log('totalFilesSize', Object.values(totalFilesSize));
  }, [totalFilesSize]);

  return (
    <Container>
      {slots.map((slot, index) => (
        <PhotoUploaderSlot
          key={index}
          index={index}
          slotData={slot}
          onUploadComplete={onUploadComplete}
          onRemove={onRemove}
          onTotalSizeChange={onTotalSizeChange}
          isSizeLimitOverflow={isSizeLimitOverflow}
        />
      ))}
      <Disclaimer singleLine={slots.length > disclaimerSingleLineValue}>
        <Typography sx={{ fontSize: '1.4rem', lineHeight: '1.53rem' }} color={isSizeLimitOverflow ? 'red' : '#fff'}>
          *{config.texts.clientForm.stepTwo.photosAmountRestriction}
        </Typography>
      </Disclaimer>
    </Container>
  );
};

export default PhotoUploader;
