import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Formik, Form, FormikProps } from 'formik';

import { TextField, Checkbox, Button } from 'src/components/form';
import { useAppDispatch, useAppState } from 'src/Context';
import PhotoUploader, { SlotData } from './PhotoUploader';

const Container = styled(Box, { label: 'Container' })(() => ({
  position: 'relative',
}));

const ButtonsContainer = styled(Box, { label: 'ButtonsContainer' })(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  flexDirection: 'row-reverse',
  marginTop: '5rem',
}));

export interface FormValues {
  story: string;
  photos: SlotData[];
  confirmation: boolean;
}

interface Props {
  onNext: () => void;
  onPrev: () => void;
}

const StepTwo = (props: Props) => {
  const { config, form } = useAppState();
  const dispatch = useAppDispatch();
  const [slots, setSlots] = React.useState<SlotData[]>(form.stepTwo.photos);
  const formikRef = React.useRef<FormikProps<FormValues>>(null);
  const { clientForm: texts } = config.texts;

  const onPhotoUploaderChange = (slots: SlotData[]) => {
    setSlots(slots);
  };

  const onPrevClick = () => {
    if (formikRef.current)
      dispatch({
        type: 'UPDATE_FORM_DATA',
        payload: { target: 'stepTwo', data: { ...formikRef.current.values, photos: slots } },
      });

    props.onPrev();
  };

  const onSubmit = (values: FormValues) => {
    dispatch({ type: 'UPDATE_FORM_DATA', payload: { target: 'stepTwo', data: { ...values, photos: slots } } });
    props.onNext();
  };

  useEffect(() => {
    // sendGAEvent(GA_PAYLOAD.secondPage);
    ReactGA.ga('send', 'pageview', '/Upload image page');
  }, []);

  return (
    <Container>
      <Typography variant="stepTitle" mt="4.6rem" component="p">
        {texts.stepTwo.title}
      </Typography>
      <Typography variant="stepSubTitle" mb="2.4rem" component="p">
        {texts.stepTwo.subTitle}
      </Typography>
      <Box>
        <Formik
          initialValues={form.stepTwo}
          innerRef={formikRef}
          validateOnBlur
          validate={(values) => {
            if (!values.confirmation) {
              return { confirmation: config.texts.clientForm.validation.required };
            }
          }}
          onSubmit={onSubmit}
        >
          <Form>
            <TextField
              name="story"
              multiline
              fullWidth
              rows={10}
              maxChars={400}
              label={texts.stepTwo.fields.story.label}
              placeholder={texts.stepTwo.fields.story.placeholder}
            />
            <PhotoUploader maxAmount={3} slots={slots} onChange={onPhotoUploaderChange} />
            <Checkbox
              name="confirmation"
              label={texts.stepTwo.fields.confirmation.label}
              defaultChecked={form.stepTwo.confirmation}
            />
            <ButtonsContainer>
              <Button type="submit" primary label={texts.nextBtn} />
              <Button type="button" label={texts.previousBtn} onClick={onPrevClick} />
            </ButtonsContainer>
          </Form>
        </Formik>
      </Box>
    </Container>
  );
};

export default StepTwo;
