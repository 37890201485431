import React from 'react';
import styled from '@mui/material/styles/styled';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { useField } from 'formik';

import Label from '../Label';
import Error from '../Error';

const Input = styled(OutlinedInput, { label: 'Input' })<OutlinedInputProps>(({ error }) => ({
  fontSize: '1.6rem',
  lineHeight: '1.75rem',
  color: '#343A40',
  backgroundColor: '#fff',
  borderRadius: '0.5rem',
  padding: '0.6rem 1.2rem',
  minHeight: '5.2rem',

  ...(error && {
    border: '0.1rem solid #FF4D4F',
  }),

  '& input': {
    padding: 0,

    '&:-webkit-autofill': {
      color: '#343A40 !important',
      WebkitBoxShadow: 'none',
      WebkitTextFillColor: '#343A40',
      backgroundColor: '#fff !important',
    },
  },

  '& fieldset': {
    border: 'none',
  },

  '&.MuiInputBase-multiline': {
    padding: '1.6rem 1.2rem 1.6rem 0',

    '& textarea': {
      paddingLeft: '1.2rem',
    },
  },
}));

const MaxChars = styled('p', { label: 'MaxChars', shouldForwardProp: (prop) => prop !== 'maxChars' })(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
  fontFamily: 'Yonit-Light',
  fontSize: '1.2rem',
  lineHeight: '1.3rem',
  color: '#fff',
  marginTop: '0.5rem',
}));

interface Props extends OutlinedInputProps {
  name: string;
  maxChars?: number;
  inputRef?: React.Ref<HTMLInputElement>;
}

const TextField = (props: Props) => {
  const { maxChars, ...inputProps } = props;
  const [field, meta, helpers] = useField(props.name);
  const shouldRenderError = !!(meta.touched && meta.error);
  const wordsAmount = getWordsAmount(field.value || '');

  const processTextChange = (text: string) => {
    const newAmount = getWordsAmount(text);

    if (maxChars && newAmount > maxChars) {
      const splittedWords = text.split(' ');

      helpers.setValue(splittedWords.slice(0, maxChars).join(' '));
      return;
    }

    helpers.setValue(text);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      // check if words limit is already exceeded:
      if (maxChars && getWordsAmount(field.value || '') >= maxChars) {
        e.preventDefault();
        return;
      }
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    processTextChange(e.target.value);
  };

  const onPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    // check if words limit is already exceeded:
    if (maxChars && getWordsAmount(field.value || '') > maxChars) {
      e.preventDefault();
      return;
    }
  };

  return (
    <FormControl sx={{ mb: '1.7rem' }} variant="outlined" fullWidth>
      <Label>{props.label}</Label>
      <Input
        id={props.name}
        {...field}
        {...inputProps}
        inputRef={props.inputRef}
        error={shouldRenderError}
        autoComplete="off"
        onKeyDown={onKeyDown}
        onChange={onChange}
        onPaste={onPaste}
      />
      <Box sx={{ position: 'relative' }}>
        {shouldRenderError && <Error error={meta.error as string | string[]} />}
        {maxChars && (
          <MaxChars>
            {wordsAmount}/{maxChars}
          </MaxChars>
        )}
      </Box>
    </FormControl>
  );
};

function getWordsAmount(text: string) {
  return text.split(' ').filter((s) => !!s.trim()).length ?? 0;
}

export default TextField;
