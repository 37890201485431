import React from 'react';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

import { FormStep } from 'src/types/form-step';

const Container = styled(Box, { label: 'Container' })(() => ({
  position: 'relative',
  marginBottom: '2.9rem',
}));

interface FilledBoxProps {
  primary?: boolean;
}
const FilledBox = styled(Box, {
  label: 'FilledBox',
  shouldForwardProp: (props) => props !== 'primary',
})<FilledBoxProps>(({ theme, primary }) => ({
  position: 'relative',
  backgroundColor: primary ? '#BD2426' : theme.palette.grey[300],
  height: '0.5rem',
  borderRadius: '0.25rem',
}));

const Marker = styled(Box, { label: 'Marker' })(() => ({
  position: 'absolute',
  left: 0,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#BD2426',
  width: '1.5rem',
  height: '1.5rem',
  borderRadius: '50%',
}));

interface Props {
  step: FormStep;
}

const ProgressBar = (props: Props) => {
  const { step } = props;
  const steps = [FormStep.STEP_1, FormStep.STEP_2, FormStep.STEP_3];
  const stepIndex = steps.indexOf(step) + 1;
  const totalSteps = steps.length;

  return (
    <Container>
      <FilledBox>
        <FilledBox primary width={`${Math.round((stepIndex / totalSteps) * 100)}%`}>
          <Marker />
        </FilledBox>
      </FilledBox>
    </Container>
  );
};

export default ProgressBar;
