import React, { useEffect } from 'react';
import * as yup from 'yup';
import ReactGA from 'react-ga';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Formik, Form, FormikProps } from 'formik';

import { TextField, Checkbox, Button } from 'src/components/form';
import { useAppDispatch, useAppState } from 'src/Context';

const Container = styled(Box, { label: 'Container' })(() => ({
  position: 'relative',
}));

const ButtonsContainer = styled(Box, { label: 'ButtonsContainer' })(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  flexDirection: 'row-reverse',
  marginTop: '5rem',
}));

const FieldWrapper = styled(Box, { label: 'FieldWrapper' })(() => ({
  marginBottom: '1.4rem',
}));

export interface FormValues {
  name: string;
  email: string;
  phone: string;
  confirmation: boolean;
}

interface Props {
  onSubmit: () => void;
  onPrev: () => void;
}
const StepThree = (props: Props) => {
  const { config, form } = useAppState();
  const dispatch = useAppDispatch();
  const formikRef = React.useRef<FormikProps<FormValues>>(null);
  const { clientForm: texts } = config.texts;
  const redText = `פרטים אישיים ליצירת קשר לא יפורסמו`;

  const validationSchema = React.useMemo(() => {
    return yup.object().shape({
      name: yup.string().test('validator', function (input) {
        if (input) {
          const splittedName = input.split(' ');

          if (input.trim() === '') {
            return this.createError({ path: this.path, message: texts.validation.stepOne.soldierName.required });
          } else if (splittedName.length < 2 || splittedName.some((name) => name.length < 2)) {
            return this.createError({ path: this.path, message: texts.validation.stepOne.soldierName.invalidValue });
          }
          return true;
        }
        return this.createError({ path: this.path, message: texts.validation.stepOne.soldierName.required });
      }),
      email: yup
        .string()
        .email(texts.validation.stepThree.email.invalidValue)
        .required(texts.validation.stepThree.email.required),
      confirmation: yup.bool().oneOf([true], texts.validation.stepThree.confirmation.required),
    });
  }, [config]);

  const onPrevClick = () => {
    if (formikRef.current)
      dispatch({
        type: 'UPDATE_FORM_DATA',
        payload: { target: 'stepThree', data: { ...formikRef.current.values } },
      });

    props.onPrev();
  };

  const onSubmit = (values: FormValues) => {
    dispatch({ type: 'UPDATE_FORM_DATA', payload: { target: 'stepThree', data: values } });
    props.onSubmit();
  };

  useEffect(() => {
    // sendGAEvent(GA_PAYLOAD.userInformationPage);
    ReactGA.ga('send', 'pageview', '/User information page');
  }, []);
  return (
    <Container>
      <Typography variant="stepTitle" mt="4.6rem" component="p">
        {texts.stepThree.title}
      </Typography>
      <Typography variant="stepSubTitle" mb="0.2rem" component="p">
        {texts.stepThree.subTitle}
      </Typography>
      <Box mb="0.8rem">
        <Typography component="span" sx={{ backgroundColor: '#EC0203', padding: '0 0.3rem' }}>
          {redText}
        </Typography>
      </Box>
      <Box>
        <Formik
          initialValues={form.stepThree}
          innerRef={formikRef}
          validateOnBlur
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <FieldWrapper>
              <TextField
                name="name"
                label={`* ${texts.stepThree.fields.name.label}`}
                placeholder={texts.stepThree.fields.name.placeholder}
              />
            </FieldWrapper>
            <FieldWrapper>
              <TextField
                name="email"
                label={`* ${texts.stepThree.fields.email.label}`}
                placeholder={texts.stepThree.fields.email.placeholder}
              />
            </FieldWrapper>
            <FieldWrapper>
              <TextField
                name="phone"
                label={texts.stepThree.fields.phone.label}
                placeholder={texts.stepThree.fields.phone.placeholder}
              />
            </FieldWrapper>
            <Checkbox
              name="confirmation"
              label={texts.stepThree.fields.confirmation.label}
              defaultChecked={form.stepThree.confirmation}
            />
            <ButtonsContainer>
              <Button type="submit" primary label={texts.sendBtn} />
              <Button type="button" label={texts.previousBtn} onClick={onPrevClick} />
            </ButtonsContainer>
          </Form>
        </Formik>
      </Box>
    </Container>
  );
};

export default StepThree;
