import createTheme from '@mui/material/styles/createTheme';

const font = {
  regular: 'Yonit-Regular',
  bold: 'Yonit-Bold',
  medium: 'Yonit-Medium',
  light: 'Yonit-Light',
};

export const theme = (builtin?: boolean) =>
  createTheme({
    direction: 'rtl',
    typography: {
      htmlFontSize: 10,
      fontFamily: font.regular,
      stepTitle: {
        fontFamily: font.regular,
        fontSize: '4.0rem',
        lineHeight: '4.38rem',
      },
      stepSubTitle: {
        fontFamily: font.regular,
        fontSize: '1.8rem',
        lineHeight: '1.97rem',
      },
    },
    palette: {
      mode: 'dark',
      primary: {
        main: '#2F80ED',
      },
      error: {
        main: '#E42323',
      },
      background: {
        default: builtin ? '#053550' : '#114e71',
        paper: '#114E71',
      },
    },
  });

export default theme;
