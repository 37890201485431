import React from 'react';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';

import { FormStep } from 'src/types/form-step';
import { useAppState } from 'src/Context';
import { submitForm } from 'src/api';
import ProgressBar from './ProgressBar';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import Final from './Final';

const ContentBox = styled(Box, {
  label: 'ContentBox',
})(() => ({
  margin: '0 auto',
  maxWidth: '760px',
  '@media (max-width: 600px)': {
    maxWidth: '100%',
  },
}));

const ContainerBox = styled(Box, {
  label: 'ContainerBox',
})(() => ({
  flexGrow: 1,
  padding: '4.4rem 8.8rem',
  '@media (max-width: 480px)': {
    padding: '3.5rem 2.0rem',
  },
}));

const FormSteps = () => {
  const { form } = useAppState();
  const [step, setStep] = React.useState<FormStep>(FormStep.STEP_1);
  const [submission, setSubmission] = React.useState(false);
  const currentStepRef = React.useRef<FormStep>(FormStep.STEP_1);

  const renderStep = () => {
    switch (step) {
      case FormStep.STEP_1:
        return (
          <StepOne
            onNext={() => {
              setStep(FormStep.STEP_2);
            }}
          />
        );

      case FormStep.STEP_2:
        return (
          <StepTwo
            onNext={() => {
              setStep(FormStep.STEP_3);
            }}
            onPrev={() => {
              setStep(FormStep.STEP_1);
            }}
          />
        );

      case FormStep.STEP_3:
        return (
          <StepThree
            onSubmit={() => {
              setSubmission(true);
            }}
            onPrev={() => {
              setStep(FormStep.STEP_2);
            }}
          />
        );

      case FormStep.FINAL:
        return <Final />;

      default:
        return null;
    }
  };

  React.useEffect(() => {
    if (submission) {
      submitForm({
        soldier: form.stepOne,
        memory: form.stepTwo,
        contact: form.stepThree,
      });
      setStep(FormStep.FINAL);
    }
  }, [submission, form]);

  React.useEffect(() => {
    // scroll to top when step changes (smoothly)

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    if (currentStepRef.current !== step) {
      window.parent.postMessage('step-changed', '*');
    }
    currentStepRef.current = step;
  }, [step]);

  return (
    <ContainerBox>
      <ContentBox>
        {step !== FormStep.FINAL && <ProgressBar step={step} />}
        {renderStep()}
      </ContentBox>
    </ContainerBox>
  );
};

export default FormSteps;
