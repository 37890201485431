import React from 'react';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';

import { useAppState } from 'src/Context';
import finalPicImg from './assets/final-pic.png';
import n12LogoImg from './assets/n12.png';
import newFinalLogo from './assets/builtin-logo.svg';

const Container = styled(Box, { label: 'Container' })(() => ({
  position: 'relative',
}));

const FinalImg = styled('img', { label: 'FinalImg' })(() => ({
  display: 'block',
  maxWidth: '100%',
  width: '55.5rem',
  opacity: 0.8,
  mixBlendMode: 'screen',
  transform: 'scale(1.2)',
  margin: '2.7rem auto 0',
  '@media (max-width: 768px)': {
    transform: 'scale(1)',
  },
}));

const FooterLogosWrap = styled(Box, { label: 'FooterLogosWrap' })(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  width: '100%',
  marginTop: '3.0rem',

  '& .logo': {
    height: '6.2rem',
  },

  '& .logo-mobile': {
    height: '5rem',
  },

  '& .n12': {
    height: '2.6rem',
  },

  '@media (max-width: 380px)': {
    '.logo-mobile': {
      height: '4rem',
    },
  },
}));

export interface FormValues {
  name: string;
  email: string;
  phone: string;
  confirmation: boolean;
}

const StepThree = () => {
  const { config } = useAppState();
  const { clientForm: texts } = config.texts;
  const isMobile = useMediaQuery('(max-width: 600px)');
  return (
    <Container>
      <Typography
        sx={{
          fontFamily: 'Yonit-Bold',
          fontSize: '5rem',
          lineHeight: '5.4rem',
          textAlign: 'center',
          mt: isMobile ? '0rem' : '6rem',
          mb: '1.9rem',
        }}
        component="p"
      >
        {texts.final.title}
      </Typography>
      <Typography sx={{ fontSize: '2.5rem', lineHeight: '2.7rem', textAlign: 'center', mb: '2.7rem' }} component="p">
        {texts.final.subTitle}
      </Typography>
      <Typography sx={{ fontSize: '2.5rem', lineHeight: '2.7rem', textAlign: 'center' }} component="p">
        {texts.final.text}
      </Typography>
      <FinalImg src={finalPicImg} />
      <FooterLogosWrap>
        <img src={newFinalLogo} className={isMobile ? 'logo-mobile' : 'logo'} />
        <img src={n12LogoImg} className="n12" />
      </FooterLogosWrap>
    </Container>
  );
};

export default StepThree;
