import React from 'react';
import styled from '@mui/material/styles/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { uploadPhoto } from 'src/api';

const Container = styled('label', { label: 'PhotoUploaderSlot' })<{ state: SlotState }>(({ state, theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '10.4rem',
  height: '10.4rem',
  border: '1px dashed #D9D9D9',
  borderRadius: '0.2rem',
  backgroundColor: '#fff',
  cursor: 'pointer',
  [theme.breakpoints.down(380)]: {
    width: '9.2rem',
    height: '9.2rem',
  },
  ...(state === 'success' && {
    backgroundColor: 'transparent',
    border: '1px solid #D9D9D9',
    pointerEvents: 'none',
  }),
  ...(state === 'error' && {
    cursor: 'pointer',
    border: '1px dashed #EC0203',
    backgroundColor: 'transparent',

    '& .plus-icon': {
      margin: '0.5rem auto',
    },
  }),

  '& input': {
    display: 'none',
  },
}));

const PlusIcon = styled('div', { label: 'PlusIcon' })<{ color: string }>(({ color }) => ({
  position: 'relative',
  width: '1.075rem',
  height: '1.075rem',
  margin: '1.1rem auto',

  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    display: 'block',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '0.1rem',
    backgroundColor: color,
    opacity: 0.85,
  },

  '&::before': {
    transform: 'translate(-50%, -50%) rotate(90deg)',
  },
}));

const Text = styled(Typography, { label: 'Text' })<{ type: 'dimmed' | 'normal' | 'error' }>(({ type }) => ({
  position: 'relative',
  textAlign: 'center',
  fontSize: '1.4rem',
  lineHeight: '2.2rem',
  color: type !== 'error' ? '#000' : '#EC0203',
  opacity: type === 'dimmed' ? 0.5 : 1,
}));

const Progress = styled(
  ({ value, className = '' }: { value: number; className?: string }) => (
    <div className={className}>
      <div className="value" style={{ width: `${value}%` }} />
    </div>
  ),
  { label: 'Progress' },
)(() => ({
  position: 'relative',
  backgroundColor: '#F5F5F5',
  borderRadius: '0.2rem',
  width: 'calc(100% - 1.6rem)',
  height: '0.2rem',
  margin: '1.8rem 0 0',

  '& .value': {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    backgroundColor: '#2F80ED',
    borderRadius: '0.2rem',
  },
}));

const CrossBtn = styled('button', { label: 'CrossBtn' })(() => ({
  position: 'absolute',
  pointerEvents: 'visiblePainted',
  zIndex: 2,
  top: '-0.6rem',
  left: '-0.6rem',
  width: '2.0rem',
  height: '2.0rem',
  padding: 0,
  border: '1px solid #868E96',
  borderRadius: '50%',
  backgroundColor: '#D9D9D9',
  cursor: 'pointer',
  outline: 'none',

  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    display: 'block',
    width: '60%',
    height: '0.1rem',
    backgroundColor: '#000',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) rotate(45deg)',
  },

  '&::after': {
    transform: 'translate(-50%, -50%) rotate(-45deg)',
  },
}));

interface Props {
  index: number;
  slotData: { state: SlotState; url?: string };
  onUploadComplete: (index: number, url: string) => void;
  onTotalSizeChange: (index: number, size: number) => void;
  onRemove: (index: number) => void;
  isSizeLimitOverflow: boolean;
}

export type SlotState = 'default' | 'uploading' | 'success' | 'error';

const MB = 1024 * 1024;
const SIZE_LIMIT = 10 * MB;

const PhotoUploader = (props: Props) => {
  const { index, slotData, onUploadComplete, onTotalSizeChange, onRemove, isSizeLimitOverflow } = props;
  const [currentState, setCurrentState] = React.useState<SlotState>(slotData.state);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [errorMsg, setErrorMsg] = React.useState('');
  const uploadText = `העלאת תמונה`;
  const uploadingText = 'Uploading';
  const errorText = `העלאה נכשלה`;
  const errorSizeText = `${Math.round(SIZE_LIMIT / MB)}MB}`;
  const tryAgainText = `נסו שנית`;

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      if (file.size > SIZE_LIMIT) {
        e.target.value = '';
        setCurrentState('error');
        setErrorMsg(errorSizeText);
        return;
      }

      setCurrentState('uploading');

      const result = await uploadPhoto({ photo: file }, (progress) => {
        setUploadProgress(progress);
      });

      if (result.success && result.data.url && !isSizeLimitOverflow) {
        setCurrentState('success');
        onUploadComplete(index, result.data.url);
        onTotalSizeChange(index, file.size);
      } else {
        setCurrentState('error');
        setErrorMsg(errorText);
      }
    }
  };

  const renderCurrentState = () => {
    switch (currentState) {
      case 'default': {
        return (
          <>
            <PlusIcon color="#000" />
            <Text type="dimmed">{uploadText}</Text>
          </>
        );
      }

      case 'uploading': {
        return (
          <>
            <Text type="normal">{uploadingText}</Text>
            <Progress value={uploadProgress} />
          </>
        );
      }

      case 'success': {
        return (
          <>
            <CrossBtn onClick={() => onRemove(index)} type="button" />
            <Box
              sx={{
                position: 'absolute',
                left: '1rem',
                top: '1rem',
                right: '1rem',
                bottom: '1rem',
                backgroundColor: '#fff',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundImage: `url(${slotData.url})`,
              }}
            />
          </>
        );
      }

      case 'error': {
        return (
          <>
            <Text type="error">{errorMsg}</Text>
            <PlusIcon className="plus-icon" color="#fff" />
            <Text type="normal" sx={{ color: '#fff' }}>
              {tryAgainText}
            </Text>
          </>
        );
      }

      default:
        return null;
    }
  };

  React.useEffect(() => {
    setCurrentState(slotData.state);
  }, [slotData.state]);

  return (
    <Container state={currentState} htmlFor={`photo-uploader-${index}`}>
      {renderCurrentState()}
      <input id={`photo-uploader-${index}`} type="file" accept="image/png, image/jpeg" onChange={onFileChange} />
    </Container>
  );
};

export default PhotoUploader;
